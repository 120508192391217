import React from 'react';
import { Link } from 'gatsby';

import { ScContainer } from '../../components/container/styled';

import {
	ScAboutUsHeroTextColumns,
	ScTellHeroWrapper,
	ScTellUpdateCustomerstWrapper,
	ScUpdateWrapper,
} from './styled';

export const CustomersUpdate = () => {
	return (
		<ScTellHeroWrapper>
			<ScContainer>
				<ScTellUpdateCustomerstWrapper>
					<ScUpdateWrapper>
						<h3>Update your website</h3>
						<p>
							Here's some useful copy about the cycle to work scheme and Gogeta Bike for you to
							update your website:
						</p>
						<ScAboutUsHeroTextColumns>
							<div>
								<p>
									<b>What is the Cycle to Work scheme?</b>
								</p>
								<p>
									Cycle to work is a Government scheme that lets PAYE employees make big savings on
									bikes and accessories by paying for them out of their gross salary, saving money
									on tax and national insurance. And because you pay monthly via salary sacrifice
									out of your monthly payslip, you can spread the cost of 12/18/24 months (depending
									on your employer's settings).
								</p>
								<p>
									<b>About Gogeta Bike</b>
								</p>
								<p>
									Our preferred cycle to work partner is Gogeta Bike, the most generous cycle to
									work scheme thanks to its low commission and no hidden surcharges. This means you
									get access to the best deals and the biggest savings when you buy a bike and/or
									accessories from us, using the scheme.
								</p>
							</div>
							<div>
								<p>
									To use Gogeta Bike your employer needs to sign up. It’s simple, quick and offering
									Gogeta Bike to staff doesn’t cost them a penny. Your employer can sign up via our
									website:{' '}
									<Link to={'/employer-cycling/plans/'}>
										<u>LINK</u>
									</Link>
								</p>
								<p>
									<b>How much can I save with Gogeta Bike?</b>
								</p>
								<p>
									Cycle to work savings are typically 24% - 39% depending on your salary. In
									addition to the tax savings, because Gogeta Bike has the lowest commissions of any
									cycle to work provider, we give you access to the best deals and discounts, so
									total savings are often in excess of 50%. See how much you could save with the
									Gogeta Bike savings calculator:{' '}
									<Link to={'/employer-cycling?section=calculator/'}>
										<u>LINK</u>
									</Link>
								</p>
							</div>
						</ScAboutUsHeroTextColumns>
					</ScUpdateWrapper>
					<ScUpdateWrapper>
						<h3>Use the Gogeta Bike savings calculator</h3>
						<p>
							You can use the code below to add the Gogeta Bike savings calculator to your website
						</p>
						<ol>
							<li>
								{`Add the following code where you'd like to embed the widget, anywhere inside the <body> tag.`}
							</li>
							<li>Alternatively, share the widget script with your development team.</li>
						</ol>
						<div className="code">{`<iframe width="100%" height="800px" src="https://calculator.gogeta.bike/" style={{ border: 'none' }}></iframe>`}</div>
					</ScUpdateWrapper>
				</ScTellUpdateCustomerstWrapper>
			</ScContainer>
		</ScTellHeroWrapper>
	);
};
